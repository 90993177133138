$(function(){
	$('.user-panel .avatar, .user-panel .nickname').click(function(){
		var _panel = $(this).parent();
		//console.log(_panel);
		_panel.toggleClass('active');
	});

	$('.beta-notice .badge').click(function(){
		$('body').toggleClass('show-beta-notice');
	});
});
