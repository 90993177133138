$(function(){

	//logout from a device
	$('.device-logout').click(function(){
		var node = $(this).closest('tr');
		var sessionId = $(this).data('id');

		$.ajax({
			type: 'GET',
			url: '/api/auth/logout?session_id=' + sessionId,
			success: function(data) {
				node.remove();
			}
		});
	});

	var Rect = function(obj) {
		obj = obj||{};

		var _self = {
			x: obj.x || 0,
			y: obj.y || 0,
			w: obj.w || 0,
			h: obj.h || 0,
			transform: function(params) {
				var n = _self.clone();
				for(var i in params) {
					n[params[i].param] += params[i].value;
				}

			},
			isInside: function(px, py) {

				if(px >= _self.x && px <= _self.x + _self.w && 
				   py >= _self.y && py <= _self.y + _self.h) {
					return true;
				}

				return false;
			},
			_clone: function() {
				var n = {};
				for(var i in _self) {
					n[i] = _self[i];
				}
				return n;
			},

			fitInto: function(rect) {
				if(rect.x < _self.x) rect.x = _self.x;
				if(rect.y < _self.y) rect.y = _self.y;

				if(rect.x + rect.w > _self.x + _self.w) rect.x = _self.x + _self.w - rect.w;
				if(rect.y + rect.h > _self.y + _self.h) rect.y = _self.y + _self.h - rect.h;
			}
		};

		return _self;
	}


	var imageScale = 100, imageWidth = 0, imageHeight = 0, imageRatio = 0;
	var action = null, moving = false, startX = 0, startY = 0, posX = 0, posY = 0;
	var mode = 'fixed', fixedWidth = 256, fixedHeight = 256;
	var format = '', filePath = '';
	var selection = new Rect(), selectable = new Rect();

	$('.image-editor .increase, .image-editor .decrease').click(function(){
		
		if($(this).hasClass('increase')) {
			imageScale += 10;
		} else {
			imageScale -= 10;
			if(imageScale < 100) {
				imageScale = 100;
			}
		}

		$('.image-editor img').css('width', imageScale + '%');
	});

	$('.image-editor .overlay').mouseleave(function(e){
		moving = false;

		e.preventDefault();
		return false;
	});

	$('.image-editor .overlay').mouseup(function(e){
		moving = false;

		e.preventDefault();
		return false;
	});


	$('.image-editor .overlay').mousedown(function(e){
		var img = $('.image-editor img');

		$('.image-editor').addClass('selected');

		startX = e.offsetX;
		startY = e.offsetY;
		
		if(mode == 'fixed') {
			action = 'move';
		} else {
			//check if click happend inside selectin => goto move mode
			//check if any corner was clicked => goto resize mode
			//else => goto select mode
			if(inSelection(startX, startY, selection)) {
				if((new Rect({x: selection.x,y:selection.y,w:25,h:25})).isInside(startX, startY)) {
					action = "resize";
				} else if((new Rect({x: selection.x+selection.w-25,y:selection.y,w:25,h:25})).isInside(startX, startY)) {
					action = "resize";
				} else if((new Rect({x: selection.x+selection.w-25,y:selection.y+selection.h-25,w:25,h:25})).isInside(startX, startY)) {
					action = "resize";
				} else if((new Rect({x: selection.x,y:selection.y+selection.h-25,w:25,h:25})).isInside(startX, startY)) {
					action = "resize";
				} else {
					action = "move";
				}
			} else {
				action = "select";
				selection.x = startX;
				selection.y = startY;
			}
		}
		
		posX = selection.x;
		posY = selection.y;

		e.preventDefault();
		return false;
	});

	var lastX, lastY;
	$('.image-editor .overlay').mousemove(function(e){
		var img = $('.image-editor img');
		var selected = $('.image-editor .selection');
		var ratio = 1;

		if(e.buttons && e.target.id == "overlay") {
			var diffX = (e.offsetX - startX);
			var diffY = (e.offsetY - startY);

			if(action == "select") {
				if(diffX < 0 && diffY < 0) {
					selection.x = startX - Math.abs(diffX);
					selection.y = startY - Math.abs(diffX) * ratio;
				} else if(diffX < 0 && diffY > 0) {
					selection.x = startX - Math.abs(diffX);
					//selection.y = startY - -1 * Math.abs(diffX) * ratio;
				} else if(diffX > 0 && diffY < 0) {
					selection.y = startY - Math.abs(diffX) * ratio;
				}

				//set width & height
				//use the ratio param if set
				selection.w = Math.abs(diffX);
				if(ratio === false) {
					selection.h = Math.abs(diffY);
				} else {
					selection.h = Math.abs(diffX) * ratio;
				}
			} else if(action == "resize") {


			} else {
				selection.x = posX + diffX;
				selection.y = posY + diffY;

				selectable.fitInto(selection);
			}

			//draw rect
			$(selected).css('top', selection.y).css('left', selection.x).css('width', selection.w).css('height',selection.h);
		}

		e.preventDefault();
		return false;
	});


	$('#file-upload').change(function(){
		$('body').addClass('loading');

		var formData = new FormData($('#file-upload-form')[0]);
		$.ajax({
			url: '/api/avatar/upload',  //Server script to process data
			type: 'POST',
			// Form data
			data: formData,
			//Options to tell jQuery not to process data or worry about content-type.
			cache: false,
			contentType: false,
			processData: false,

			success: function(data) {
				filePath = data.file;
				$('.image-editor img').attr('src', data.original_url);
			},

			error: function() {
				$('.loading').hide();
			}
		});
	});

	$('.image-editor img').load(function(){
		imageWidth = $('.image-editor img').width();
		imageHeight = $('.image-editor img').height();
		imageRatio = imageHeight / imageWidth;

		$('.image-editor').removeClass('landscape').removeClass('portrait').removeClass('square');
		$('.image-editor img').attr('style', null);

		if(imageWidth > imageHeight) {
			format = 'landscape';
			if(mode == 'fixed') $('.image-editor img').css('max-height', fixedHeight);
		} else if(imageWidth < imageHeight) {
			format = 'portrait';
			if(mode == 'fixed') $('.image-editor img').css('max-width', fixedWidth);
		} else {
			format = 'square';
			if(mode == 'fixed') $('.image-editor img').css('max-width', fixedWidth);
		}

		$('.image-editor').addClass(format);

		//setup selectable area
		selectable.x = $('.image-editor img').position().left;
		selectable.y = $('.image-editor img').position().top;
		selectable.w = $('.image-editor img').width();
		selectable.h = $('.image-editor img').height();

		//if mode is fixed setup selection
		if(mode == 'fixed') {
			selection.x = selectable.x + (selectable.w - fixedWidth) / 2;
			selection.y = selectable.y + (selectable.h - fixedHeight) / 2;
			selection.w = fixedWidth;
			selection.h = fixedHeight;
		
			$('.image-editor .selection').css('top', selection.y).css('left', selection.x).css('width', selection.w).css('height',selection.h);
			$('.image-editor').addClass('selected');
		}
	});

	$('.image-editor img').load();

	$('#save-avatar').click(function(){
		$.ajax({
			type: 'POST',
			url: '/api/avatar',
			data: {
				crop: {
					width: selectable.w,
					height: selectable.h,
					x: selection.x - selectable.x,
					y: selection.y - selectable.y,
					x2: selection.x - selectable.x + selection.w,
					y2: selection.y - selectable.y + selection.h,
				},
				file: filePath
			}
		}).done(function(){
			var msg = $('<div>').addClass('alert alert-success').text('Gespeichert!').prependTo($('.image-editor'));
			setTimeout(function() {
				msg.fadeOut(function(){
					$(this).remove()
				});
			}, 5000);
		});

	})

	$('.avatar-selector img').click(function(){
		filePath = $(this).data('url');
		$('.image-editor img').attr('src', $(this).data('src'));
	});

	$('#charter').change(function(){
		if($(this).is(':checked')) {
			$('form button').attr('disabled', null);
		} else {
			$('form button').attr('disabled', 'disabled');
		}
	});
});